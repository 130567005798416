// Generated by Framer (236a21b)

import { addFonts, cx, CycleVariantState, Image, optimizeAppear, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["Ays3V4x0U"];

const serializationHash = "framer-QWeql"

const variantClassNames = {Ays3V4x0U: "framer-v-1vjjyjp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0.5, mass: 1, stiffness: 200, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, transition: transition1, x: 0, y: 0}

const animation1 = {opacity: 0.8, rotate: 10, rotateX: 10, rotateY: 10, scale: 1.2, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Ays3V4x0U", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><ImageWithFX {...restProps} __framer__presenceAnimate={optimizeAppear("animate", "1vjjyjp", animation, undefined)} __framer__presenceInitial={optimizeAppear("initial", "1vjjyjp", animation1, undefined)} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", intrinsicHeight: 1080, intrinsicWidth: 1920, pixelHeight: 1080, pixelWidth: 1920, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/VhY8uu9mwdkjXjREIpx6uQvOM.jpg", srcSet: "https://framerusercontent.com/images/VhY8uu9mwdkjXjREIpx6uQvOM.jpg?scale-down-to=512 512w,https://framerusercontent.com/images/VhY8uu9mwdkjXjREIpx6uQvOM.jpg?scale-down-to=1024 1024w,https://framerusercontent.com/images/VhY8uu9mwdkjXjREIpx6uQvOM.jpg 1920w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vjjyjp", className, classNames)} data-framer-appear-id={"1vjjyjp"} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Ays3V4x0U"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1r3yqmp"} data-framer-name={"Cover"} layoutDependency={layoutDependency} layoutId={"SwOFKm8no"} style={{backgroundColor: "var(--token-abb270e3-c883-490a-af93-d8a73b419377, rgba(0, 0, 0, 0.5))"}}/></ImageWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QWeql.framer-nwze3i, .framer-QWeql .framer-nwze3i { display: block; }", ".framer-QWeql.framer-1vjjyjp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 400px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1210px; }", ".framer-QWeql .framer-1r3yqmp { flex: 1 0 0px; height: 100%; overflow: hidden; position: relative; width: 1px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QWeql.framer-1vjjyjp { gap: 0px; } .framer-QWeql.framer-1vjjyjp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QWeql.framer-1vjjyjp > :first-child { margin-left: 0px; } .framer-QWeql.framer-1vjjyjp > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 1210
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPhUneDn4C: React.ComponentType<Props> = withCSS(Component, css, "framer-QWeql") as typeof Component;
export default FramerPhUneDn4C;

FramerPhUneDn4C.displayName = "Header D";

FramerPhUneDn4C.defaultProps = {height: 400, width: 1210};

addFonts(FramerPhUneDn4C, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})